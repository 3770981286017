import React from "react";
import "./inventry.css";
import Layout from "../Layout/Layout";
import inventryimage from '../../images/inventry.webp'

const Inventry = () => {
  return (
    <Layout   title={'Inventory Management System with Latest Features'}>
      <br />
      <section>
        <div className="w-full px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">Inventory Management System</h6>

              <div className="  px-6">
                <p>
                  The inventory Management System is a vital component of your
                  whole supply chain. Because it enables you to trace the
                  buying, storage, and sale of your items at any particular
                  moment in time. Once you can effectively track. How much
                  material you have where this is, inventory management turns
                  from a difficult, manual operation to a critical factor of
                  your business’s growth plan. Further, Get multi-techno
                  services and improve your business inventory management with
                  the best ERP integrated software
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="w-full md:w-[70vw]   mx-auto">
          <div className="  px-6 my-[5rem]">
            <img
              src={inventryimage}
              className="shadow-xl  w-full "
              alt="Inventory-Management-System"
            />
          </div>
        </div>
      </section>

      <section>
        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">
                Purpose of Inventory Management System
              </h6>

              <div className="attend-p  cash">
                <p>
                  Inventory management’s major goal is to guarantee that there
                  are enough items to fulfill demand while producing excess, or
                  surplus inventory.<b> Medcloud</b> helps you in maintaining
                  your inventory management software that is integrated with
                  ERP.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">Features</h6>

              <div className="attend-p  cash">
                <p>
                  An inventory management system is a tool that allows you to
                  track goods across your business’s supply chain. further, It
                  optimizes the entire spectrum spanning from order placement
                  with your vendor to order delivery to your customer. Moreover,
                  mapping the complete journey of a product.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="hrlist-container  px-6">
          <div className="hrlist-width">
            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Setup:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Item Main Category</li>
                  <li>Sub Category</li>
                  <li>Definition</li>
                  <li>UOM Definition</li>
                  <li>Store Definition</li>
                </ul>
              </div>
            </div>

            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Transaction Modules:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Inventory Opening Balance</li>
                  <li>Inventory Adjustment</li>
                  <li>Material Issue Note</li>
                  <li>Material Return Note</li>
                  <li>Store Transfer</li>
                  <li>IGP (Inward Gate Pass)</li>
                  <li>OGP (Outward Gate Pass)</li>
                  <li>GRN (Goods Receipt Note)</li>
                </ul>
              </div>
            </div>

            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Reports:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Stock Balance</li>
                  <li>Stock Ledger</li>
                  <li>Inward and further Outward gate passes</li>
                  <li>Cost Center Wise Consumption comparison report</li>
                  <li>Min and Max Stock Level Base Reorder Report</li>
                  <li>Stock in Hand Value Wise</li>
                  <li>Inter-Stores Transfer Summary Report</li>
                  <li>Main Category Wise Report</li>
                  <li>Sub Category Wise Report</li>
                </ul>
              </div>
            </div>
          </div>
          <p className="w-full  md:w-[85vw]  mx-auto px-6  my-2 ">
            Accurate inventory management is essential for running a profitable
            product firm. Further Stock tracking regularly might assist to avoid
            stock mistakes and other issues. Following are the features of
            inventory management:
          </p>
        </div>
      </section>

      {/* part 5 */}

      <section>
        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">Barcoding and Tagging</h6>

              <div className="attend-p  cash">
                <p>
                  The outstanding aspect of the inventory management system is
                  the removal of common human mistakes. moreover, Manual data
                  entry can lead to mistakes, but scanning a barcode saves
                  employees a lot of time.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">
                Reporting on Company Operations
              </h6>

              <div className="attend-p  cash">
                <p>
                  Monitoring of an inventory firm necessitates keeping those in
                  command up to date on different business operations such as
                  the vehicle’s location etc
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">
                Notifications About Inventory data
              </h6>

              <div className="attend-p  cash">
                <p>
                  A good inventory management system has a securities alarm
                  function. moreover, The program outlines numerous consequences
                  that may arise as a result of the low supply of a certain item
                  in the notifications.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">Inventory backup and security</h6>

              <div className="attend-p  cash">
                <p>
                  If inventory software is hacked, Even so, the data is
                  protected by a backup that business owners may access and
                  utilize. As a result, backup and inventory security avoid any
                  disruptions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">
                The product’s demand and supply
              </h6>

              <div className="attend-p  cash">
                <p>
                  Inventory software helps businesses manage their inventory
                  effectively. moreover, Executives properly manage the buyers
                  and sellers of the company’s products.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">Last Thoughts</h6>

              <div className="attend-p  cash">
                <p>
                  Effective inventory management is critical to the global
                  performance of supply networks. further, The advantages of
                  these systems are obvious, and as time passes, more and more
                  businesses will continue to use similar solutions. after that
                  Multi-Techno provides you with complete solutions for any ERP
                  software.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Part 6 */}
      <section>
        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">
                Benefits of Inventory Management that is Integrated with ERP
              </h6>

              <div className="attend-p  cash   py-4   px-6">
                <p>
                  <b>Inventory Management System strategies</b> can assist you
                  in saving money and further maintaining a correct stock count.
                  Furthermore, when you integrate an ERP system with inventory
                  management features, you will realize even more benefits.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Part 7 */}

      <section>
        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">
                  1 . Evaluate Inventory Levels Across the Organization:
                </span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  ERP systems can give a global picture of orders across all
                  departments, from selling to accounting through delivery.
                  moreover, centralized purchasing eliminates duplication, and
                  the option to buy in bulk saves money.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">2 . Improve Forecasting:</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  Data collecting and analytics software can give insights into
                  patterns. You may also enhance your stock predictions by
                  understanding patterns
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">
                  3 . Optimize Your Entire Inventory:
                </span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  A solid inventory management system aids in maintaining the
                  proper further mix of stock and amounts at the lowest possible
                  carrying costs. further, It will assist you in ensuring that
                  you’ll never have excessive amounts on hand.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">
                  4 . Manual Tasks Can Be Automated:
                </span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  Stock-taking, further receiving, and fulfillment may all be up
                  by barcode and RFID scanning. at the same time Using software
                  decreases mistakes caused by manual entry and frees employees
                  from repetitive activities.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6   mb-[5rem]">
          <div className="w-full    md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">5 . Profit Has increased:</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  A better knowledge of supply and moreover availability leads
                  to higher inventory turnover, which affects profitability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Inventry;
