import React from "react";
import Layout from "../Layout/Layout";
import accountimage from '../../images/accountpayable.webp'

const Payableaccount = () => {
  return (
    <Layout   title={'Account Payable System '}>
      <br />
      <section>
        <div className="px-6  mt-4">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">Account Payable System</h6>

              <div className="attend-p  cash  px-6">
                <p>
                  Accounts Payable System (APS) is the word used when a company
                  buys things on credit and has to pay them back quickly.
                  Moreover, It is recorded as a liability in bookkeeping and
                  comes under the heading of payables. To prevent default,
                  Account Payable is a financial instrument that must be paid on
                  time. further, The software’s also available for account
                  payable. Account payable software of{" "}
                  <b>Med-Cloud Erp system</b> has a full supply chain solution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="px-6">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">Order to Pay Cycle</h6>
            </div>
          </div>
        </div>

        <section>
          <div className="w-full md:w-[65vw]  h-auto  my-[4rem]  px-6   mx-auto">
            <div className="shadow-lg">
              <img
                src={accountimage}
                className="w-full"
                alt="purchase management system"
              />
            </div>
          </div>
        </section>
      </section>

      <section>
        <div className="px-6">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <div className="attend-p  cash  ">
                <p >
                  <b> Account Payable System</b> refers to the set of policies,
                  procedures, and practices employed by a company with respect
                  to managing its trade credit purchases. In summary, they
                  consist of seeking trade credit lines, acquiring favorable
                  terms of purchase, and managing the flow and timing of
                  purchases so as to efficiently control the company’s working
                  capital.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* part 2 */}

      <section>
        <div className="px-6">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">
                Features of Purchase Management System
              </h6>
            </div>
          </div>
        </div>
      </section>

      {/* part 4 */}

      <section>
        <div className="hrlist-container">
          <div className="hrlist-width">
            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Setup:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Sales Tax Definition</li>
                  <li>WHT Definition</li>
                  <li>Payment Terms</li>
                </ul>
              </div>
            </div>

            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Reports: </h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Invoice Summary Report of Purchase</li>
                  <li>Prepayment Party Wise</li>
                  <li>Unpaid Invoices</li>
                  <li>Ledger</li>
                  <li>Vendor and Invoice Wise Aging</li>
                  <li>Aging Summary</li>
                  <li>Payment Register</li>
                  <li>Summary</li>
                  <li>WHT Party Wise</li>
                  <li>Sales Tax Summary</li>
                </ul>
              </div>
            </div>

            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Transactions:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Purchase Invoice </li>
                  <li>Prepayment</li>
                  <li>Debit Memo</li>
                  <li>Credit Memo</li>
                  <li>Invoice Payment</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Part 5 */}

      <section>
        <div className="px-6">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">
                Features of Accounts Payable System
              </h6>
            </div>
          </div>
        </div>
      </section>

      <section  className="mb-[5rem]">
        <div className="px-6">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">Standard invoice</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  A standard invoice is a concise paperwork that is used to bill
                  for the products or services you have given. further, It would
                  include a description of the item or service, pricing,
                  acceptable payment methods, and delivery dates for the
                  invoice.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">Debit memo</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  A debit memo statement is a form, of paperwork that notifies a
                  buyer that the seller is debiting or raising its amount. In
                  accounts payable, hence raising the number of the buyer’s
                  accounts payable due to extreme circumstances.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">Credit memo</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  A credit memo is a short form of a memorandum, which is a
                  statement delivered by a supplier of goods or services to the
                  customer that reduces the amount due to the seller under the
                  conditions of a previous invoice.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">Prepayments</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  Prepayments are payments that are paid in advance to the
                  supplier. When invoices process completed by a company in the
                  improvement of receiving products or services. Then the
                  prepayment will be cleared with consideration of the
                  procedure.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">Cash invoice</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  Small company owners are particularly fond of cash invoicing.
                  A cash invoice is a statement of a selling transaction that
                  occurred, and it is issued when the payment system is cash.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">Payable invoice </span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  A firm that invoices its consumer for the purchase of products
                  and finally the services through invoice is referred to as
                  ‘Payable by Invoice. It generates after the GRN number.
                  moreover, the invoice contains crucial information such as the
                  price for goods received and services performed. As well as
                  additional charges, company data, and a due date.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full md:w-[85vw]   mx-auto">
            <div className="hrattend-heading">
              <h6 class="list-title ">
                <span className="inventry-h">Withholding tax invoice</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  A withholding tax is sometimes known as a retention tax.
                  further, it is a legal requirement that requires the payer of
                  the client. Further, invoice to withhold or reduce the tax
                  from the payment and remit it to the state.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Payableaccount;
