import React from "react";
import Layout from "../Layout/Layout";
import hrm1 from "../../images/hrm1.png";
import hrm4 from "../../images/hrm 4.png";

const Account = () => {
  return (
    <Layout    title={'Best Accounts and Finance Management System'} keywords={''}  >
      <section className="w-full   ">
        <div className="mt-[5rem] w-full md:w-[85vw]  md:mx-auto ">
          <div className="hrpay-image   w-full  h-full">
            <img src={hrm1}  alt="Accounts and Finance Management System  image"  className="w-full " />
          </div>
        </div>
      </section>

      <br />
      <section className="w-full   py-5">
        <div className=" cash     w-[85vw]   mx-auto ">
          <div className="cash-attend-col">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">
                Accounts and Finance Management System
              </h6>

              <div className="attend-p mt-3 px-3 cash">
                <p className="text-lg">
                  Accounts and Finance Management System of Multi-Techno ERP
                  system is the best generally for accounting software for small
                  companies. So, a lot of small company accounting experts
                  utilize Erp implementation. Butt there are also a variety of
                  online training materials and forums where you may receive
                  help if you need it.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section>
          <div className="  w-full     md:w-[85vw]   mx-auto  mt-5">
            <div className="hrpay-image">
              <img src={hrm4}   alt="Employee self service portal image"  className="w-full " />
            </div>
          </div>
        </section>
      </section>

      {/* part 3 */}

      <section>
        <div className="  w-full  md:w-[85vw]  md:mx-auto   ">
          <div className="cash-attend-col">
            <div className="mt-3  px-6">
              <h6 class="border-l-[5px]   border-red-600 py-2 pl-2  text-2xl  md:text-3xl  font-bold ">
                The Features of Accounting and Finance Software
              </h6>

              <p className="px-6">
                A general ledger contains all the accounts for recording
                transactions relating to a company’s assets, liabilities,
                owners’ equity, revenue, and expenses.
              </p>
              <br />
              <p className="px-6">Moreover, In modern accounting software,</p>
              <span className="font-bold ">
                The Modules of General Ledger Like as: Given these points
              </span>
              <div className="list-ul   px-6 ">
                <ul>
                  <li>accounts payable</li>
                  <li>accounts receivable</li>
                  <li> cash management</li>
                  <li>fixed assets</li>
                  <li>purchasing and projects.</li>
                </ul>

                <p className="cash-help">
                  Medcloud can <b>help you design</b>, implement, and maintain a
                  robust cash management system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* part 3 */}

      <section>
        <div className="hrlist-container">
          <div className="hrlist-width">
            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Setup:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Chart of Account</li>
                  <li>Fiscal Year Open</li>
                  <li>Month Open Close</li>
                  <li>Cost Centers Definitions</li>
                  <li>Voucher type Definition</li>
                  <li>Balance Sheet Setup</li>
                  <li>Profit and Loss Setup</li>
                </ul>
              </div>
            </div>

            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Reports:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Chart of Account</li>
                  <li>General Ledger</li>
                  <li>Trial Balance</li>
                  <li>Profit or Loss Account</li>
                  <li>Notes to the Accounts </li>
                  <li>Balance Sheet</li>
                  <li>Notes to the Accounts</li>
                  <li>Profit and Loss likeness for the Year</li>
                  <li>Cash Flow Statement</li>
                </ul>
              </div>
            </div>

            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Transaction Modules:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Cash Bank Payment</li>
                  <li>Cash Bank Receipts</li>
                  <li>Journal Voucher</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* part  5 */}

      <section className="w-full">
        <div className=" w-full px-4   md:px-0 md:w-[85vw]  mx-auto">
          <div className="cash-attend-col ">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">
                The Benefits of Accounting and Finance Software
              </h6>

              <p className="px-6">
                Accounting and finance software is a useful tool for accounting
                experts since it broadly improves the productivity of the
                finance department. Apart from that, this program offers many
                furthermore advantages. Given these points
              </p>

              <div className="list-ul  px-6">
                <ul>
                  <li>Access Accounting Data Any Time</li>
                  <li>connection is easier</li>
                  <li>Accounting Security has Improved</li>
                  <li>
                    Accuracy in accounting and furthermore bookkeeping has been
                    enhance
                  </li>
                  <li>Real-time Reporting</li>
                  <li>Save Money and further Cut Costs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full px-6  md:w-[85vw]  mx-auto">
          <div className="cash-attend-col">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">
                General Ledger of Accounts and Finance Management System
              </h6>

              <div className="attend-p  cash">
                <p>
                  Accounting entries across different companies’ entities are
                  whole in real-time. Structured, conform, and data is provided
                  to create strong financial reports using the general ledger
                  ERP module. Furthermore Accounting software help by digitizing
                  accounting documentation and simplifying financial operations
                  like cost management and accounts receivable. Given these
                  points
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Part 7 */}

      <section className="pb-[5rem] px-6  pt-3">
        <div className="w-full  md:w-[85vw]  mx-auto ">
          <div className="cash-attend-col">
            <div className="hrattend-heading">
              <h6 class="list-title font-bold">
                <span className="inventry-h">1 . Closing Balance Reports</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  A closing balance is an amount, positive or negative, that
                  remains in an account. At this time, the maximum of business
                  accountants will present management with a closing balance.
                  This is normally in the form of a management accounts package.
                  That includes the month’s balance as well as the total amount.
                  Moreover, You can manage your closing reports with the
                  business software of Multi-Techno Integrated software
                  solutions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  md:w-[85vw]  mx-auto">
          <div className="cash-attend-col">
            <div className="hrattend-heading">
              <h6 class="list-title font-bold ">
                <span className="inventry-h">2 .Period Define</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  For the most part, Any time frame used for financial reporting
                  is referred to as an accounting period. Furthermore,
                  Activities that occur within a specified date range are
                  included in the accounting period’s reports in Erp software.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  md:w-[85vw]  mx-auto">
          <div className="cash-attend-col">
            <div className="hrattend-heading">
              <h6 class="list-title font-bold ">
                <span className="inventry-h">3 . Vouchers</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  Once the money is received by the provider. Moreover, The
                  vouchers are verified as paid. Further Payments are made and
                  recorded in the proper ledger accounts. Also, Accounting
                  software allows businesses to better manage their financial
                  operations.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  md:w-[85vw]  mx-auto">
          <div className="cash-attend-col">
            <div className="hrattend-heading">
              <h6 class="list-title font-bold ">
                <span className="inventry-h">4 . Financial Statements</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  Financial statements are documents that detail the financial
                  and operating performance of a business. Moreover, Accounting
                  accounts are often audited by government agencies, auditors,
                  firms, and others. For tax, finance, and new investments, as
                  well as to ensure accuracy.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  md:w-[85vw]  mx-auto">
          <div className="cash-attend-col">
            <div className="hrattend-heading">
              <h6 class="list-title font-bold ">
                <span className="inventry-h">5 . Profit and Loss Report</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  Med-Cloud Erp fulfillment maintains profit and loss reports
                  for your business. All accounting functions are manage from a
                  single screen, making accounting more trustable and simple.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  md:w-[85vw]  mx-auto">
          <div className="cash-attend-col">
            <div className="hrattend-heading">
              <h6 class="list-title font-bold ">
                <span className="inventry-h">6 .Balance Sheet </span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  The trial balance is a financial or accounting report that
                  shows the current amounts in all accounts in the general
                  ledger. Med-Cloud offers the best software for accounting
                  professionals who need to handle data from clients to complete
                  tax returns or financial statements. Because you’ll achieve
                  tasks in less time. And with higher competence than you could
                  with any other software. Due to a low learning curve and
                  smooth data entry methods.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  md:w-[85vw]  mx-auto">
          <div className="cash-attend-col">
            <div className="hrattend-heading">
              <h6 class="list-title font-bold ">
                <span className="inventry-h">7 .Cash Flow Statement</span>
              </h6>

              <div className="attend-p  cash">
                <p>
                  A cash flow financial statement summarises all cash inflows a
                  company gets from its continuing activities as well as from
                  external investment sources. It also covers all cash outflows
                  for the company and investing operations throughout a certain
                  period.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Account;
