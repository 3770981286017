import { Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import Home from "./components/pages/Home";
import Products from "./components/pages/Products";
import Solution from "./components/pages/Solution";
import Suport from "./components/pages/Suport";
import Hrpay from "./components/Featuredservice/Hrpay";
import Header from "./components/Layout/Header";
import Contactus from "./components/pages/Contactus";
import AOS from "aos";
import "aos/dist/aos.css";
// import Featured from './components/Featuredservice/Featured';
import ManuEpr from "./components/Featuredservice/ManuEpr";
import Account from "./components/Featuredservice/Account";
import Cashmanagement from "./components/Featuredservice/Cashmanagement";
import Inventry from "./components/Featuredservice/Inventry";
import Purchase from "./components/Featuredservice/Purchase";
import Payableaccount from "./components/Featuredservice/Payableaccount";
import Whatsapp from "./components/pages/Whatsapp";
AOS.init();

function App() {
  useEffect(() => {
    AOS.init = { duration: "1000" };
    AOS.refresh();
  }, []);

  return (
    <>
      <Whatsapp />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/product" element={<Products />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/hrpay" element={<Hrpay />} />
        <Route path="/Manufacturingerp" element={<ManuEpr />} />
        <Route path="/accounts" element={<Account />} />
        <Route path="/cash" element={<Cashmanagement />} />
        <Route path="/inventory" element={<Inventry />} />
        <Route path="/purchase" element={<Purchase />} />
        <Route path="/accountpayable" element={<Payableaccount />} />
        {/* <Route path='/support'  element={<Suport/>}/> */}
      </Routes>
    </>
  );
}

export default App;
