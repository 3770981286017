import React, { useEffect, useState } from "react";
import { TfiClose } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { FiAlignJustify } from "react-icons/fi";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import logo from "../../images/logo.png";

const Header = () => {
  const [nav, setNav] = useState(false);
  const [heading, setHeading] = useState(false);
  const [showShadow, setShowShadow] = useState(false);
  const [submenuStates, setSubmenuStates] = useState({});
  const [stickyNav, setStickyNav] = useState(false);
  const handleSubmenuToggle = (submenuKey) => {
    // Toggle the state of the specified submenu
    setSubmenuStates((prevState) => ({
      ...prevState,
      [submenuKey]: !prevState[submenuKey],
    }));
  };

  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowShadow(scrollPosition > 400);
      setStickyNav(scrollPosition > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Define your navigation links as an array of objects
  const navLinks = [
    {
      text: "Home",
      url: "/",
    },

    { text: "About Us", url: "#" },
    { text: "products", url: "/product" },

    { text: "Solution", url: "/solution" },

    {
      text: "Software",
      submenu: true,
      dropdown: [
        { name: " HR and Payroll System", link: "/hrpay" },
        {
          name: "Manufacturing ERP Management system",
          link: "/Manufacturingerp",
        },
        {
          name: "    Accounts and Finance  Management System ",
          link: "/accounts",
        },
        {
          name: "  Cash Management System  ",
          link: "/cash",
        },
        { name: " Inventory Management System ", link: "/inventory" },
        { name: "  Purchase Management System        ", link: "/purchase" },
        {
          name: "   Account Payable Management System  ",
          link: "/accountpayable",
        },
      ],
    },

    { text: "Contact Us", url: "/contact" },
  ];

  return (
    <>
      {/* <div className="flex bg-black border-b-2  py-5 text-white justify-center sm:justify-center md:justify-center">
        <Link to="/">
          <img className="h-[10rem] w-[20rem]" src={logo} alt="alpha logo,alphaenterprises logo" />
        </Link>
      </div> */}

      <div
        className={`text-[#fff] Georgian bg-[#000] px-0 md:px-6 w-full pb-3 md:p-0  lg:py-[.5rem] border-b-2 border-red-700 ${
          showShadow ? "shadow-md" : ""
        } ${
          stickyNav
            ? "sticky top-0 z-50  transition-all duration-300 ease-in-out"
            : ""
        }`}
      >
        <div className=" flex justify-between  ">
          <div className="flex    ">
            <div className="">
              <Link to="/">
                <img
                  className="h-[5.5rem]    w-[16rem]   ml-[0rem]   lg:ml-[0rem]     xl:ml-[5rem]"
                  src={logo}
                  alt="alpha logo,alphaenterprises logo"
                />
              </Link>
            </div>

            <div className="lg:hidden hover:text-orange-500    ml-[10rem]  md:ml-[25rem]   lg:ml-0  ">
              <button
                onClick={handleNav}
                className=" top-15      mt-[2rem]     rounded-lg  px-3 p-3 text-lg font-medium bg-[#fff] shadow-sm text-black"
              >
                <FiAlignJustify />
              </button>
            </div>
          </div>

          <div className="h-1 flex flex-col   ml-10 md:flex-row  md:hover:text-orange-400   lg:text-[1rem]  xl:text-[1.2rem]   md:py-[30px] lg:pt-[3rem] items-center font-bold     xl:px-7 md:px-0  lg:mr-[0rem] xl:mr-[10rem]  text-sm ">
            <div className="">
              <div className="hidden lg:inline Georgian">
                <ul className="flex justify-right hover:cursor-pointer ">
                  {navLinks.map((li, index) => (
                    <li
                      key={index}
                      className=" px-3  hover:rounded duration-400   relative group"
                    >
                      <Link
                        to={li.url}
                        className="flex   text-[#fff] no-underline  transition-all duration-300 hover:text-red-700 "
                      >
                        {li.text}
                        {li.submenu ? (
                          <span className="text-md md:ml-2  inline ">
                            <BsChevronDown />
                          </span>
                        ) : null}
                      </Link>

                      {li.submenu && (
                        <div className="absolute top-7 left-1 w-[250px] bg-[#000000dd] text-white  hidden group-hover:block shadow-lg z-[999] ">
                          <div className="pt-2 py-1">
                            <div className="w-3 h-4 absolute mt-5 rotate-45"></div>

                            <div className="py-2 px-3 flex flex-col">
                              {li.dropdown.map((mysublinks, subIndex) => (
                                <div
                                  className="text-black    hover:text-red-500"
                                  key={subIndex}
                                >
                                  <Link
                                    to={mysublinks.link}
                                    className="text-white no-underline text-[17px]"
                                  >
                                    <p className="text-md font-light hover:ml-2 hover:text-red-600 ease-out  duration-100 border-b border-black">
                                      {mysublinks.name}
                                    </p>
                                    <p className=""></p>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            nav
              ? "Georgian bg-[#000000e7] absolute right-0  top-0 w-80  z-[1000] rounded-sm shadow-md overflow-y-hidden text-[#fff]"
              : "fixed right-[-100%] "
          }
        >
          <div className=" min-h-full h-[100vh] z-[1000] overflow-y-auto">
            <ul className="pt-4 px-6  f-h pb-3">
              <div className="flex justify-end md:pr-8 pt-4 ">
                <TfiClose
                  onClick={handleNav}
                  className="rounded p-1 font-bold hover:bg-black/5"
                  size={24}
                />
              </div>
              <div className="mt-6 text-[18px] z-1000 ">
                {navLinks.map((li, index) => (
                  <>
                    <Link to={li.url} className="text-[#fff] no-underline">
                      <li
                        key={index}
                        className="cursor-pointer Georgian py-2 pl-3 font-medium   hover:rounded hover:bg-black/5 hover:text-red-700 "
                      >
                        <span className="" onClick={handleNav}>
                          {li.text}
                        </span>
                      </li>
                    </Link>
                    {li.submenu && (
                      <BsChevronDown
                        className={`${
                          submenuStates[index] && "rotate-180"
                        } absolute  mt-[-39px] right-8 `}
                        onClick={() => handleSubmenuToggle(index)}
                      />
                    )}

                    <div className=" ml- mt-2  flex flex-col justify-center border-b-2 border-[#fff] items-center max-h-[40vh] overflow-y-auto">
                      {li.submenu && submenuStates[index] && nav && (
                        <ul className="pt-7">
                          {li.dropdown.map((submenuItem, subIndex) => (
                            <Link
                              to={submenuItem.link}
                              className="text-black no-underline"
                            >
                              <li
                                key={subIndex}
                                className=" text-white text-md ease-out   transition-all duration-200  gap-x-4 cursor-pointer    rounded-md py-2   hover:rounded hover:bg-black/5 hover:text-red-700"
                                onClick={() => {
                                  setNav(!nav);
                                }}
                              >
                                {submenuItem.name}
                              </li>
                            </Link>
                          ))}
                        </ul>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
