import React, { useState } from "react";
import Layout from "../Layout/Layout";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import blog1 from '../../images/blog1.png'
import blog2 from '../../images/blog2.png'
import blog3 from '../../images/blog3.png'
import blog4 from '../../images/blog4.png'
import product1 from '../../images/Network_Cameras.png'
import product2 from '../../images/HDCVI_Cameras2.png'
import product3 from '../../images/PTZ_Cameras.png'
import product4 from '../../images/Thermal_Cameras.png'
import product5 from '../../images/network_Recorders-2.png'
import product6 from '../../images/HCVR1.png'
import product7 from '../../images/EZ-IP.png'
import product8 from '../../images/27.png'
import product9 from '../../images/access32.png'
import product10 from '../../images/Alarms1.png'
import product11 from '../../images/Mobile_Traffic.png'
import product12 from '../../images/Transmission.png'
import productbanner  from '../../images/productsbanner.jpg'

const Products = () => {
  const [expanded, setExpanded] = useState("");

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };
  const images = [
    {
      id: 1,
      title: "Network Cameras",
      items: [
        "WizSense Series",
        "Ultra-AI Series",
        "Pro-AI Series",
        "Ultra Series",
        "Pro Series",
        "Lite Series",
        "Panoramic Series",
        "Special Series",
      ],
      image:product1,
    },
    // Add other product sections similarly

    {
      id: 2,
      title: "HDCVI Cameras ",
      items: [
        "Pro 4K&6MP Series",
        "Pro Starlight Series",
        "Lite Plus Series",
        "Lite Series",
        "Cooper Series",
        "PIR & IoT Series",
        "Panorama Series",
        "PoC Series",
        "Micro-size Series",
        "HDCVI Accessories",
      ],
      image: product2,
    },

    {
      id: 3,
      title: "PTZ Cameras ",
      items: [
        "WizSense Series",
        "Network PTZ Cameras",
        "HDCVI PTZ Cameras",
        "Positioning Systems",
        "Anti-Corrosion Cameras",
        "Explosion-Proof Cameras",
      ],
      image:product3,
    },

    {
      id: 4,
      title: "Thermal Cameras ",
      items: [
        "Ultra Series",
        "Pro Series ",
        "Lite Series",
        "Anti-Corrosion Series",
      ],
      image:product4,
    },

    {
      id: 5,
      title: "Network Recorders ",
      items: [
        "WizSense Series",
        "Ultra-AI Series",
        "Pro-AI Series",
        "Ultra Series",
        "Pro Series",
        "Lite Series",
        "Panoramic Series",
        "Special Series",
      ],
      image: product5,
    },

    {
      id: 6,
      title: "HDCVI Recorders ",
      items: [
        "Pro AI Series",
        "Lite AI Series",
        "Pro H.265 4K Series",
        "Lite H.265 4K Series",
        "Lite H.265 1080P Series",
        "Lite H.265 720P Series",
        "Cooper Series",
        "PoC Series",
        "ATM & Encoder & X86 Series",
      ],
      image: product6,
    },

    {
      id: 7,
      title: "EZ-IP  ",
      items: ["Network Cameras", "Network Recorders"],
      image: product7,
    },

    {
      id: 8,
      title: "Video Intercoms ",
      items: [
        "IP Products",
        "Analog Products",
        "Emergency Phone Terminals",
        "Kits",
        "Accessories",
      ],
      image: product8,
    },

    {
      id: 9,
      title: "Access Control ",
      items: [
        "Controllers",
        "Standalone",
        "Time Attendance",
        "Readers",
        "Modules",
        "Accessories",
      ],
      image: product9,
    },

    {
      id: 10,
      title: "Alarms ",
      items: [
        "Wireless Products",
        "Wired Products",
        "Field Surveillance Unit",
        "Kits",
      ],
      image: product10,
    },

    {
      id: 11,
      title: "Mobile & Traffic ",
      items: [
        "Traffic Enforcement Products",
        "Entrance & Exit Control Products",
        "Parking Products",
        "Mobile",
        "MPT",
      ],
      image: product11,
    },

    {
      id: 12,
      title: "Transmission ",
      items: [
        "PoE Switches",
        "ePoE Switches",
        "Access Switches",
        "Aggregation Switches",
        "Core Switches",
        "Wireless Devices",
        "Accessories",
        "Accessories",
      ],
      image:product12,
    },
  ];

  const blog = [
    {
      id: 1,
      img: blog1,
      title: "ANPR",
      description:
        "Improve efficiency and security while reducing costs Automatic  number-plate recognition (ANPR) is a technology that uses optical character recognition on images",
    },
    {
      id: 2,
      img: blog2,
      title: "ePoE",
      description:
        "  Alpha ePoE technology achieves long distance transmission of power, video, audio, and control signals over 800 meters at 10Mbps",
    },
    {
      id: 3,
      img: blog3,
      title: "HDCVI Technology",
      description:
        " High Definition Composite Video Interface (HDCVI) is Dahua’s patented in-house technology. HDCVI is an over-coaxial cable video standard that enables ......",
    },
    {
      id: 4,
      img: blog4,
      title: "  Predictive Focus Algorithm (PFA)",
      description:
        " With a rise in demand for long-distance surveillance solutions, Large optical zoom PTZ cameras are gaining  widespread usage. However,....",
    },
  ];

  return (
    <Layout    title={'products'}>
      <div className="relative md:flex md:items-center">
        <div className="ProductTopDiv mt-5  h-[20rem] absolute md:p-10">
          <h1 className="heading text-3xl text-center md:text-left lg:text-left md:text-7xl text-white mb-4 md:mb-10">
            PRODUCTS
          </h1>
          <p className="   text-white text-lg  ml-3    md:ml-0 lg:ml-0  md:text-3xl font-normal">
            Innovative Technology | Reliable Quality | End-to-End Service
          </p>
        </div>
        <img
          src={productbanner}
          alt=""
          className="w-full    h-[30vh]  md:h-full lg:h-full md:w-auto"
        />
      </div>

      {/* ============================part 2 ============================ */}

      <div className=" bg-black  text-white ">
        <div className="row   mx-auto w-[80vw]  pb-[3rem]">
          <div className="pt-[3rem]">
            <div className="theme-section-module text-center">
              <h2 className="uppercase text-[3rem] font-[400]  text-white">
                All Products
              </h2>
              <div
                className="w-full md:w-[30rem] mx-auto mt-7 border-b-[2px] border-gray-700 relative"
                data-wow-delay=".3s"
              >
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <svg
                    className="text-[#fcaa52] w-[4rem] h-[4rem] text-center"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <p className=" text-center text-[16px]   mt-8  md:text-center">
            Abiding by the philosophy of "Innovation for All", Dahua offers a
            wide portfolio of security-related products, ranging from IPC, NVR,
            HDCVI cameras, HCVR, PTZ cameras, thermal cameras, Access Control,
            Video Intercom, Alarms, Mobile & Traffic products, display &
            control, VMS and so on. Dahua products are based on an open platform
            that features easy integration with third party partners through a
            standard SDK.
          </p>
        </div>
      </div>

      <div className="w-full  bg-black pb-[2rem]">
        <div className="w-[85%] mx-auto  mb-[5rem]">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3     xl:grid-cols-4 gap-6">
            {images.map((image, index) => (
              <div key={index} className="grid-cols-1">
                <img src={image.image} alt="" className="w-full" />
                <Accordion
                  expanded={expanded === `panel-${index}`}
                  onChange={handleAccordionChange(`panel-${index}`)}
                >
                  <AccordionSummary
                    style={{ background: "#dc1b24", color: "white" }}
                  >
                    <div className="flex justify-between   relative">
                      <div>
                        <p className="text-base">{image.title}</p>
                      </div>
                      <div className="ml-[20rem]     md:ml-[16rem]  lg:ml-[12rem]       xl:ml-[15rem]  absolute">
                        {expanded === `panel-${index}` ? (
                          <FaEyeSlash className="text-[1.5rem]" />
                        ) : (
                          <FaEye className="text-[1.5rem]" />
                        )}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {image.items.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* ===============================part 2 ===================================== */}

      <div className=" bg-[#00000027]   ">
        <div class=" w-[85vw]  mx-auto pt-5  mb-[2rem]">
          <div class="flex   flex-col    md:flex-row lg:flex-row   justify-between  ">
            <div class="w-full">
              <h2 className="text-[2rem] font-[600]">Key Technologies</h2>
            </div>
            <div class="w-full font-[600]">
              <h2 className="text-[2rem]  "> </h2>
            </div>
          </div>
        </div>

        <div className="container    w-[95vw]   xl:w-[85vw] pb-8  mx-auto ">
          <div className="grid grid-cols-1  gap-5  md:grid-cols-2   lg:grid-cols-3   xl:grid-cols-4  md:gap-2">
            {/* Card 1 */}

            {blog.map((data) => (
              <div
                key={data.id}
                className="DivCard   md:pb-3  px-8  md:px-2 lg:px-2 "
              >
                <div className="DivCardImg hovereffect ">
                  <img
                    src={data.img}
                    alt="ANPR"
                    className="card-img-top          w-full h-full object-cover"
                  />
                </div>
                <div className="bg-gray-200    text-gray-600 px-4 py-5">
                  <h3 className="text-lg font-semibold"> {data.title} </h3>
                  <p className="mt-2 text-sm">{data.description}</p>
                  <button class="btn btn-primary bg-black hover:bg-[#fff]  hover:text-black text-white font-bold py-2 mt-4 px-4 rounded">
                    {" "}
                    Learn More
                  </button>
                </div>
              </div>
            ))}

            {/* Card 4 */}
            {/* <div className="DivCard h-[80vh]  px-8   md:px-2 lg:px-2">
              <div className="DivCardImg hovereffect">
                <img
                  src=""
                  alt="Predictive Focus Algorithm (PFA)"
                  className="card-img-top w-full h-full object-cover"
                />
              </div>
              <div className="bg-gray-200 text-gray-600 px-4 py-3">
                <h3 className="text-lg font-semibold">
                
                </h3>
                <p className="mt-2 text-sm">
                 
                </p>

                <button class="btn btn-primary bg-black  hover:bg-[#111]  text-white font-bold py-2 my-4 px-4 rounded">
                  {" "}
                  Learn More
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
