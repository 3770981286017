import React from "react";
import "./cashmanagement.css";
import cash from "../../images/cash.webp";
import Layout from "../Layout/Layout";
import cashpay from "../../images/cashimg.webp";

const Cashmanagement = () => {
  return (
    <Layout   title={'Cash Management System | Cash Management Services'}>
      <section className="w-full">
        <div className="cash-banner">
          <div className="w-full relative">
            <img src={cash} alt="Cash-Management-System" className="w-full h-96 object-cover" />
            <h1 className="w-[85vw]  text-center   md:text-left text-white text-5xl font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
              Cash Management System
            </h1>
          </div>
        </div>
      </section>

      <section>
        <div className="  w-full px-6">
          <div className=" w-full   md:w-[85vw]   md:mx-auto   ">
            <div className="hrattend-heading cash">
              <h6 class="attend-title cash">Cash Management System</h6>

              <div className=" px-6">
                <p>
                  Medcloud develops cash management software with unique
                  qualities closely related to the client’s goals. Further, ERP,
                  accounting systems, bank balances, and petty cash management
                  are all integrated with cash management systems and software.
                  Furthermore, It is based on precise cash management forecasts
                  and efficient <b>cash payment management</b>. Even so,
                  Organizations demand consistent, clear, and easy software.
                  Further, You can get the perfect ERP module from Medcloud
                  Integrated Solution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* part 3 */}

      <section>
        <div className="w-full px-6">
          <div className=" w-full   md:w-[85vw]   md:mx-auto ">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">
                Features of Cash Management System
              </h6>

              <div className="px-6">
                <p>
                  Cash management refers to a broad area of finance involving
                  cash collection, handling, and usage. Further, Cash management
                  systems and services for managing liquidity, account balances,
                  payments, and other cash management functions. You can
                  monitor, predict, and manage cash using customizable reports.
                  Moreover, All of this happens in real-time to support the most
                  accurate decision-making.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* part 4 */}

      <section>
        <div className="hrlist-container">
          <div className="hrlist-width">
            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Setup:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Banks Definition</li>
                  <li>Cheque Book Definition</li>
                </ul>
              </div>
            </div>

            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Transactions:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Bank Transfer</li>
                  <li>Bank Reconciliation</li>
                  <li>Online Slip Transfer</li>

                  <li>Collection and Routs</li>
                </ul>
              </div>
            </div>

            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Reports:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Cash Inflow Outflow</li>
                  <li>Bank Inflow Outflow</li>
                  <li>Bank Inflow Outflow Summary</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* part 5 */}

      <section>
        <div className="w-full px-6">
          <div className="w-full   md:w-[85vw]   md:mx-auto">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">
                Banks Define Cash Management System
              </h6>

              <div className="px-6">
                <p>
                  <b>Medcloud</b> provides a cash and bank account opening form
                  in which you add all the information about the banks.
                  Furthermore, <b> Cash management software</b> facilitates
                  transaction administration. Moreover, it Enables users to open
                  a bank account in the software. Also, it allows consumers to
                  connect digitally.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* part 6 */}

      <section>
        <div className="  w-full md:w-[50vw]  mx-auto   my-8 ">
          <div className=" w-full h-full   px-6 mx-auto">
            <img src={cashpay}  alt="Cash-Management-System-image" className="shadow-xl" />
          </div>
        </div>
      </section>

      {/* part 7 */}

      <section>
        <div className="w-full  px-6">
          <div className="w-full   md:w-[85vw]   md:mx-auto">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">Cheque Book Define</h6>

              <div className="px-6">
                <p>
                  A chequebook is intended and designed to make writing checks
                  easier for businesses. Further, You can open a chequebook in
                  the software and fill out a form in the software for your
                  specific function. Moreover, It keeps track of every tab of a
                  cheque issued by the Bank. Also, it’s made to make chequebooks
                  for many banks by creating a demand for a particular bank
                  cheque.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  px-6">
          <div className="w-full   md:w-[85vw]   md:mx-auto">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">Cash in Outflow Statement</h6>

              <div className="px-6">
                <p>
                  Cash-in and cash-out details are presented as a statement in
                  cash flow management. Further, By indicating how the money
                  went in and out of the firm, the idea of cash flows provides
                  the connection between the financial reports.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  px-6">
          <div className="w-full   md:w-[85vw]   md:mx-auto">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">Bank in Outflow Statement</h6>

              <div className="px-6">
                <p>
                  On the other hand, banks are not required to prepare a cash
                  flow statement as part of an individual liquidation of
                  accounts under this statement. Despite this, some banks
                  provide a cash flow statement in their yearly reports and
                  separate termination of funds by using cash management
                  services.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  px-6">
          <div className="w-full   md:w-[85vw]   md:mx-auto">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">Cash in Outflow Summary</h6>

              <div className="px-6">
                <p>
                  All cash flow statements are included in the cash in and
                  outflow summary. Further, It shows the cash flow report and
                  details of the company’s cash inflows and outflows over a
                  specified period. Moreover, It reveals if the income
                  statement’s revenues have been fully received.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  px-6">
          <div className="w-full   md:w-[85vw]   md:mx-auto">
            <div className="hrattend-heading">
              <h6 class="cash-attend-title ">Bank in Outflow Summary</h6>

              <div className="px-6">
                <p>
                  All Bank’s In And Outflow Details Are Included In The Summary
                  Section. So That The Data Can Be Analyzed And Evaluated
                  Anytime. Furthermore, As A Business Grows, There Is A Need To
                  Maintain Increasing Volumes Of Data On Financial Transactions
                  Easily. Moreover, Cash Planning Services Are Used In Banks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* part 7  */}

      <section>
        <div className="w-full  px-6">
          <div className="w-full   md:w-[85vw]   md:mx-auto">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">Bank Reconciliation Statement</h6>

              <div className="px-6">
                <p>
                  A bank reconciliation balances an entity’s bank account with
                  its business statements by summarizing banking and commercial
                  activities. The report details the deposits, withdrawals, and
                  other transactions in a bank account over a given period.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="w-full  px-6   mb-[4rem]">
          <div className="w-full   md:w-[85vw]   md:mx-auto">
            <div className="hrattend-heading">
              <h6 class="attend-title cash">Benefits:</h6>
              <div className="list-ul   px-6">
                <ul>
                  <li>Tracking your cash</li>
                  <li>Reconciliation</li>
                  <li>Reporting and analytics</li>
                  <li>Compliance and security</li>
                  <li>Make more informed financial choices.</li>
                  <li>Reduce overhead costs</li>
                </ul>

                <p className="cash-help">
                  Medcloud can <b>help you design</b>, implement, and maintain a
                  robust cash management system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Cashmanagement;
