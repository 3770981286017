import React, { useState } from "react";
import Layout from "../Layout/Layout";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import solutionbanner from "../../images/solutionsbanner.jpg";
import building from "../../images/Building.png";
import goverment from "../../images/Government.jpg";
import transport from "../../images/Transportation.jpg";
import retail from "../../images/Retail.jpg";
import banking from "../../images/BankingFinance.jpg";
import critial from "../../images/Critical.jpg";
import warehouse from "../../images/Warehousing.png";
import blog1 from "../../images/SafeSchool.jpg";
import blog2 from "../../images/SmartFactory.jpg";
import blog3 from "../../images/PerimeterProtection.png";
import blog4 from "../../images/Face.jpg";

const Solution = () => {
  const [expanded, setExpanded] = useState("");

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };
  const images = [
    {
      id: 1,
      title: "Government ",
      items: ["Traffic Management", "Safe City "],
      image:goverment,
    },
    // Add other product sections similarly

    {
      id: 2,
      title: "Transportation ",
      items: ["Mobile Vehicles", "Parking", "Harbors", "Airports"],
      image: transport,
    },

    {
      id: 3,
      title: "Building ",
      items: ["Residential & Commercial"],
      image: building,
    },

    {
      id: 4,
      title: "Retail ",
      items: ["Smart Retail"],
      image: retail,
    },

    {
      id: 5,
      title: "Banking & Finance ",
      items: ["Banking & Finance"],
      image:  banking,
    },

    {
      id: 6,
      title: "Critical Infrastructure ",
      items: ["Oil & Gas", "Telecommunication", "Electric Power"],
      image: critial,
    },

    {
      id: 7,
      title: "Warehousing & Logistics ",
      items: ["Smart Express Logistics Solution"],
      image: warehouse,
    },
  ];

  const cardData = [
    {
      id: 1,
      imageSrc:blog1,
      title: "Smart Factory",
      description:
        "Due to various factors such as semi-closed factory management, complicated surrounding environment, and personn ......",
    },
    {
      id: 2,
      imageSrc:
    blog2,
      title: "Perimeter Protection",
      description:
        "With the perimeter making the first line of defense against unwanted intruders, industrial, civil and residential security ......",
    },
    {
      id: 3,
      imageSrc: blog3,
      title: "Face Recognition",
      description:
        "Face recognition is mainly used for identification. Due to the popularity of video surveillance, many video ....",
    },

    {
      id: 4,
      imageSrc:
     blog4,
      title: "Safe School ",
      description:
        "The safety of students and teachers is always the first concern in implementing a school surveillance solution ......",
    },
  ];

  return (
    <Layout   title={'Solution'}>
      <div className="relative md:flex md:items-center   ">
        <div className="ProductTopDiv   mt-5 h-[20rem] absolute md:p-10">
          <h1 className="heading text-3xl text-center md:text-left lg:text-left md:text-7xl text-white mb-4 md:mb-10">
            SOLUTION
          </h1>
          <p className="   text-white text-lg  ml-3    md:ml-0 lg:ml-0  md:text-3xl font-normal">
            Innovative Technology | Reliable Quality | End-to-End Service
          </p>
        </div>
        <img
          src={solutionbanner}
          alt=""
          className="w-full    h-[30vh]  md:h-full lg:h-full  md:w-auto"
        />
      </div>

      {/* ============================part 2 ============================ */}

      <div className="w-full  pb-[5rem] bg-black text-white">
        <div className="row   mx-auto w-[80vw]  mb-[3rem]">
          <div className="pt-[3rem]">
            <div className="theme-section-module text-center">
              <h2 className="uppercase text-[3rem] font-[400]  text-white">
                Solution
              </h2>
              <div
                className="w-full md:w-[30rem] mx-auto mt-7 border-b-[2px] border-white relative"
                data-wow-delay=".3s"
              >
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <svg
                    className="text-red-700 w-[4rem] h-[4rem] text-center"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <p className=" text-center text-[16px]   mt-8  md:text-center">
            As a leading security solution provider, Dahua offers end-to-end
            solutions for various applications and vertical industries including
            traffic management, government facilities, retail, and banking &
            finance and so on. Application solutions such as Face Recognition
            and LPR feature not only cameras, recorders, and VMS, but also open
            interfaces for convenient 3rd party integration.
          </p>
        </div>

        <div className="w-[85%] mx-auto   ">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3    xl:grid-cols-4 gap-6">
            {images.map((image, index) => (
              <div key={index} className="grid-cols-1">
                <img src={image.image} alt="" className="w-full" />
                <Accordion
                  expanded={expanded === `panel-${index}`}
                  onChange={handleAccordionChange(`panel-${index}`)}
                >
                  <AccordionSummary
                    style={{ background: "#dc1b24", color: "white" }}
                  >
                    <div className="flex justify-between   relative">
                      <div>
                        <p className="text-base">{image.title}</p>
                      </div>
                      <div className="    ml-[20rem]     xl:ml-[14rem]  absolute">
                        {expanded === `panel-${index}` ? (
                          <FaEyeSlash className="text-[1.5rem]" />
                        ) : (
                          <FaEye className="text-[1.5rem]" />
                        )}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {image.items.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* ===============================part 2 ===================================== */}

      <div className="bg-gray-300 ">
        <div className=" w-[85vw]  mx-auto  py-[3rem]">
          <div className="flex   flex-col    md:flex-row lg:flex-row  justify-between  ">
            <div className="w-full">
              <h2 className="text-[2rem] font-[600]">SMB Solutions</h2>
            </div>
            <div className="w-full font-[600]">
              <h2 className="text-[2rem]  ">Solutions by Application</h2>
            </div>
          </div>
        </div>

        <div className="container w-[85vw]  mx-auto  pb-[4rem]">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {cardData.map((card) => (
              <div className="DivCard" key={card.id}>
                <div className="DivCardImg">
                  <img
                    src={card.imageSrc}
                    alt={`${card.title} image    `}
                    className="card-img-top w-full"
                  />
                </div>
                <div className="bg-[#F5F5F5]  text-[#666] px-[20px]  py-[15px]  text-[14px] ">
                  <h3 className="text-[17px] text-[#333]  font-[400] ">
                    {card.title}
                  </h3>
                  <p className="my-[1.3rem]  ">{card.description}</p>
                  <button className="btn btn-primary bg-black hover:bg-[#fff]  hover:text-black  text-white font-bold py-2 px-4 rounded">
                    Learn More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Solution;
