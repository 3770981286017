import React from "react";
import "./manuEpr.css";
import { useState } from "react";
import manuerp from "../../images/manufacture.png";
import Layout from "../Layout/Layout";
import manuhome from "../../images/manuhome.png";
import ERPmanu from '../../images/manuerp.webp'
import dashboard from '../../images/dashboard.jpeg'


const ManuEpr = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Layout   title={'Best ERP for the Manufacturing Sector | Alpha-Enterprises'}>
      <section className="py-[4rem]   bg-gray-300">
        <div className="   w-full grid   grid-cols-1 md:grid-cols-2 md:w-[85vw]  md:mx-auto ">
          <div className="px-6 md:px-0">
            <div className="image-div">
              <img src={manuerp}   alt="Manufacturing-ERP-System-dashboard" />
            </div>
          </div>

          <div className="  mt-5 px-6">
            <h2 className="content-title  text-4xl font-bold ">
              Streamline Operations and Enhance Productivity
            </h2>

            <p className="content-detail">
              Enhance Your Manufacturing Efficiency with ERP Management:
              Integrate Processes and Optimize Operations on a Single Platform.
            </p>
          </div>
        </div>
      </section>

      <section className="  w-full    md:w-[85vw]  mx-auto   md:py-[5rem]">
        <div class="elementor-background-overlay"></div>

        <div className=" about-main   mx-auto">
          <div className="about-data  ">
            <h3 className="title text-center ">
              Manufacturi<span className="data-underline1">ng</span>&nbsp;ERP
              System
            </h3>

            <p className="about-detail text-lg    px-6 md:px-[10rem]   mx-auto  md:text-center mt-5">
              Manufacturing ERP streamlines business operations by integrating
              all fundamental processes into a centralized platform, improving  efficiency and productivity.
            </p>

            <div className="about-image     px-6  md:px-0 py-5">
              <img src={manuhome}  alt="Manufacturing-ERP-System-dashboard"  />
            </div>
          </div>
        </div>
      </section>

      {/* part end  */}

      <section className="w-full  py-5">
        <div className="">
          <div className="attend-col">
            <div className="hrattend-heading">
              <h6 class="attend-title ">Definition Modules:</h6>
            </div>
          </div>
        </div>

        <section className="w-full   md:w-[80vw]   mx-auto">
          <div className="  w-full  mt-3 px-6 md:px-[12rem]">
            <div className="hrpay-image    w-full h-full ">
              <img src={ERPmanu}  alt="ERP-Works-Manufacturing-Industry-image"  />
            </div>
          </div>
        </section>
      </section>

      <section>
        <div className="hrlist-container">
          <div className="hrlist-width">
            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Setup:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>&nbsp;BOM Define</li>
                  <li>Work center define</li>
                  <li>Operation define</li>
                  <li>Routings</li>
                  <li>BOM Management</li>
                  <li>
                    <a href="#" target="_blank" rel="noopener">
                      Manufacturing
                    </a>
                    &nbsp;Order
                  </li>
                  <li>Work Orders</li>
                </ul>
              </div>
            </div>

            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Transactions:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Production plan &amp; control</li>
                  <li>Gate inward</li>
                  <li>Work Orders</li>

                  <li>Batching</li>
                  <li>Dispatch to Party</li>

                  <li>Cost centers</li>
                  <li>Quality Control</li>
                </ul>
              </div>
            </div>

            <div className="hrlist-col">
              <div className="list-heading">
                <h6 class="list-title ">Reports:</h6>
              </div>

              <div className="list-ul">
                <ul>
                  <li>Production Expense Report</li>
                  <li>Daily production Report</li>
                  <li>Department Wise Production Report</li>
                  <li>Waste report</li>
                  <li>Quality Reports</li>
                  <li>Each Dept Stock Level</li>
                  <li>PPC status</li>
                  <li>Separate P and Loss for Dept.</li>
                  <li>Separate Balance sheet For Dept</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="epr-container">
          <div className="epr-width">
            <div className="epr-left">
              <div className="hrlist-col2">
                <div className="list-heading1 py-2 border-l-[5px] border-red-600 ">
                  <h6 class="list-title1 ml-2  font-bold  text-4xl">
                    Benefits of Manufacturing ERP
                  </h6>
                </div>

                <div className="mt-5 px-6 ">
                  <ul className="list-disc   ">
                    <li>Increase Efficiency</li>
                    <li>Productive Enhancement</li>
                    <li>24\7 Customer Support</li>
                    <li>Estimating Organizational Metrics</li>
                    <li>Immediately Available for Reporting</li>
                    <li>Availability for Competitive Advantage</li>
                    <li>Data Analysis Efficiently</li>
                    <li>Adaptability to Changes</li>
                    <li>Helping IT Activities</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="epr-right">
              <div className="epr-image">
                <img src={dashboard}  alt="ERP-Works-Manufacturing-Industry-dashboard-image"  className="shadow-lg" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <br />
      <br />
    </Layout>
  );
};

export default ManuEpr;
