import React from "react";
import {
  FaEnvelope,
  FaFacebookF,
  FaGlobeEurope,
  FaHeart,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
  FaTwitter,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { TiSocialGooglePlus } from "react-icons/ti";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <section className="bg-[#111] text-white border-t-[3px] border-red-700">
        <div className="w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-[90vw] mx-auto">
            {/* Content Column */}
            <div className="mt-5 col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2">
              <div>
                <div className="address px-8 mx-0 md:mx-auto">
                  <h3 className="text-left my-8 border-b-2 border-red-700 pb-2 w-[10.5rem] sm:text-left text-2xl font-bold">
                    Office Address
                  </h3>
                  <ul className="text-left leading-[2rem]">
                    <li className="flex text-[1rem]">
                      <FaLocationDot className="mr-2 text-red-700 mt-2" /> Alpha
                      Enterprises(Pvt) Ltd
                    </li>
                    <li className="flex text-[1rem]">
                      <FaLocationDot className="mr-3 text-[1.5rem] text-red-700 mt-2" />{" "}
                      FF 01, 5th Floor ,51 Business Hub ,Business Park ,Gulberg
                      Green Islamabad-44000 - Pakistan
                    </li>
                    <li className="flex text-[1.1rem]">
                      <FaPhoneAlt className="mr-2 text-red-700 mt-2" />{" "}
                      +92-345-5185310
                    </li>
                    <li className="flex text-[1.1rem]">
                      <FaPhoneAlt className="mr-2 text-red-700 mt-2" />{" "}
                      +92-51-2758667
                    </li>
                    <li className="flex text-[1.1rem]">
                      <FaPhoneAlt className="mr-2 text-red-700 mt-2" />{" "}
                      +92-3084482002
                    </li>

                    <li className="flex text-[1.1rem]">
                      <FaEnvelope className="mr-2 text-red-700 mt-2" />{" "}
                      tahir@alphaenterprises.com.pk
                    </li>
                    <li className="flex text-[1.1rem]">
                      <FaEnvelope className="mr-2 text-lg text-red-700 mt-2" />{" "}
                      alphaenterprises.isb@gmail.com
                    </li>
                    {/* <li className="flex text-[1.1rem]">
                      <FaGlobeEurope className="mr-2 text-red-700 mt-2" />{" "}
                      www.alphaenterprises.com.pk
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>

            <div className="my-6">
              <aside>
                <div className="menu-our-services-container px-8 md:pr-[8rem]">
                  <aside className="my-6 font-bold">
                    <h5 className="text-2xl border-b-2 border-red-700 pb-2 w-[9rem]">
                      Our Services
                    </h5>
                  </aside>
                  <ul className="text-lg leading-[3rem]">
                    <li className="border-b pb-2 border-[#ddd]">
                      <Link
                        to="/"
                        className="hover:text-red-600 ease-out mt-5 duration-100"
                      >
                        Home
                      </Link>
                    </li>
                    <li className="border-b pb-2 border-[#ddd]">
                      <Link
                        to="/product"
                        className="hover:text-red-600 ease-out mt-5 duration-100"
                      >
                        Products
                      </Link>
                    </li>
                    <li className="border-b pb-2 border-[#ddd]">
                      <Link
                        to="/solution"
                        className="hover:text-red-600 ease-out mt-5 duration-100"
                      >
                        Solution
                      </Link>
                    </li>
                    <li className="border-b pb-2 border-[#ddd]">
                      <Link
                        to="/contact"
                        className="hover:text-red-600 ease-out mt-5 duration-100"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
              <aside
                id="block-28"
                className="widget widget_block wow animate fadeInUp"
                data-wow-delay=".3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <ul className="" />
              </aside>
            </div>

            <div className="px-[2rem] py-10">
              <h1 className="text-2xl my-3 font-bold border-b-2 border-red-700 pb-2 w-[5rem]">
                Search
              </h1>
              <div className="flex mt-12">
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  className="border-2 border-white w-[rem] md:w-[rem] px-2 text-black py-2"
                />
                <button
                  className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 bg-red-700 text-white shadow-md hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                  type="button"
                >
                  Search
                </button>
              </div>
              <ul className="myFooterSocialIcons gap-4 flex mt-9 social-network">
                <li className="icoFacebook rounded-[40px] hover:bg-blue-600 hover:text-white h-[2.5rem] border-2 border-[#fff] w-[2.5rem]">
                  <Link to="#">
                    <FaFacebookF className="text-center text-lg ml-2 mt-2" />
                  </Link>
                </li>
                <li className="rounded-[40px] hover:bg-blue-600 hover:text-white h-[2.5rem] border-2 border-[#fff] w-[2.5rem]">
                  <FaTwitter className="text-center text-lg ml-2 mt-2" />
                </li>
                <li className="rounded-[40px] hover:bg-red-700 hover:text-white h-[2.5rem] border-2 border-[#fff] w-[2.5rem]">
                  <FaInstagram className="text-center text-lg ml-2 mt-2" />
                </li>
                <li className="rounded-[40px] hover:bg-blue-600 hover:text-white h-[2.5rem] border-2 border-[#fff] w-[2.5rem]">
                  <FaLinkedin className="text-center text-lg ml-2 mt-2" />
                </li>
                <li className="rounded-[40px] hover:bg-red-600 hover:text-white h-[2.5rem] border-2 border-[#fff] w-[2.5rem]">
                  <TiSocialGooglePlus className="text-center text-lg ml-2 mt-2" />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="text-center mt-[3rem]">
          <div className="bg-[#000] pt-2 text-lg">
            <div className="py-3">
              <marquee>
                {/* <FaHeart /> */}
                All Rights Reserved. © 2024
                <b className="ml-1 text-red-700">
                  Alphaenterprises.com.pk
                </b>{" "}
                &nbsp;
                {/* <FaHeart /> */}
                &nbsp; Developed By :{" "}
                <strong className="text-red-700">medcloud.com.pk</strong>
                &nbsp; Contact :{" "}
                <strong className="text-red-700">+923236311542</strong>
              </marquee>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
