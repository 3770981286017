import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import Layout from "../Layout/Layout";
import { FaRegStar } from "react-icons/fa6";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import Typography from "@mui/material/Typography";
import MultiCarosel from "./MultiCarosel";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { FaRegThumbsUp } from "react-icons/fa";
import ubank from "../../images/ubank.png";
import moblink from "../../images/moblink.jpg";
import Dubaibank from "../../images/dubaibank.jpg";
import banner1 from "../../images/1.jpg";
import banner2 from "../../images/2.jpg";
import banner3 from "../../images/3.jpg";
import banner4 from "../../images/4.jpg";
import banner5 from "../../images/5.jpg";
import service1 from "../../images/service1.jpg";
import service2 from "../../images/service2.jpg";
import service3 from "../../images/service3.jpg";
import service4 from "../../images/service4.jpg";
import clientbg from "../../images/clientbg.jpg";
import clientbg1 from "../../images/clientbg1.jpg";

const Home = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const images = [
    {
      id: 1,
      img: banner1,
    },
    {
      id: 2,
      img: banner2,
    },
    {
      id: 3,
      img: banner3,
    },
    {
      id: 4,
      img: banner4,
    },
    {
      id: 5,
      img: banner5,
    },
  ];

  const services = [
    {
      id: 1,
      img: service1,
      title: " CCTV SYSTEM",
      description:
        "CCTV System is an affective prevention technology. Video Survelliance (CCTV) utilizes the latest digital technology to Monitor access to secure areas, un-authorized activities, theft or criminal damage and personal safety or even provide a remote response to an intrusion.      ",
    },
    {
      id: 2,
      img: service2,
      title: " ACCESS CONTROL SYSTEM",
      description:
        "From single store to chain of retails or the whole edifice Access Control System (ACS) is designed to control Access for Employees and Visitors by utilizing multiple technologies such as swipe cards, proximity cards, PIN entry keypads and sophisticated biometric devices, security systems can be designed around existing hardware to provide an effective solutions for a single or multiple sites.      ",
    },
    {
      id: 3,
      img: service3,
      title: " INTRUDER ALARM & PANIC ALARM SYSTEM",
      description:
        "This system identifies genuine incidents of forced entry, un-authorized access or persoal attack, which generates effective response if any of these occurences happen.      ",
    },
    {
      id: 4,
      img: service4,
      title: " ATTENDANCE SYSTEM",
      description:
        "Time Attendance System allows the company to manage, moniotr employees entries, exits, breaks, absence and leaves. Time Attendance system enables to prepare a detail report of employees who are not holding up to your standards of employment.      ",
    },
  ];

  const alpha = [
    {
      id: 1,
      icon: FaRegThumbsUp,
      title: "WHO WE ARE        ",
      description:
        "Alpha Enterpriese Pvt. Ltd is a specail Security Solutions provider company throughtout Pakistan to Major high sreet Brands. Government establishment, Financial sector, Small and Medium Businesses and Homes.     Alpha was established in 2015 and have its setups in Rawalpindi, Lahore and Karachi. Our high standards and work ethos have also made us hugely popular in private and commercial sector and have successfully helped to build great business relationships with our valued clients.",
    },

    {
      id: 2,
      icon: IoSettingsOutline,
      title: "WHAT WE DO      ",
      description:
        "We offer a wide range of services including CCTV installation , Alarm Systems , Time Attendance devices, Walk-through gates , Networking and much more. Depending on requirements of clients. Our team of expersts can advise you the best way to protect and monitor your home, businness and offices. Whether you need CCTV remote monitoring or an intruder alarm designed and installed, we can create a truly bespoke product to fit your budget and space.      ",
    },

    {
      id: 3,
      icon: FaRegEye,
      title: "WHY CHOOSE US      ",
      description:
        "Our Mission is to deliver a sustainable profit for the benefits of staff and customers through the provision of market-leading security systems and services. Responsive to staff and customers by listening closely to their needs.Reliable, efficient and fair through a consistent, open and honest culture.Deliver outstanding service through effective teamwork. ",
    },
  ];

  const contentData = [
    {
      id: 1,
      ptag: "“We would like to complement for the excellent services provided by M/s Alpha Enterprises Pvt Ltd in terms of Surveillance  Fire Alarm System to Dubai    Islamic Bank Ltd. We have always been pleased with the services received from all of its staff.",
      post: "  - Manager Administration North - ",
      location: "Dubai Islamic Bank Ltd",
    },
    {
      id: 2,
      ptag: "“U Microfinance Bank Ltd can safely &amp; thankfully recommend M/s Alpha Enterprises Pvt Ltd to anycompany who desires to hire their services and certainly ,havefull confidence on them for the products services being provided.”",
      post: " - Manager Security Health Safety Environment (SHSE)",
      location: "U Microfinance Bank Ltd  ",
    },
    {
      id: 3,
      ptag: "“We would like to complement for excellence services provided by M/s Alpha Enterprises Pvt Ltd in terms of Surveillance Systems to Silk Bank Ltd(North Region Branches).",
      post: "   - Regional Head Administration-North -",
      location: "  Silk Bank Ltd",
    },

    {
      id: 4,
      ptag: "This is to certify that M/s Alpha Enterprises Pvt Ltd is working with our organization since March 2019. We found them very dedicated to the work assigned. They are result oriented, professional and sincere.",
      post: " - Manager Administration -",
      location: "Mobilink Microfinance Bank Ltd",
    },
  ];

  return (
    <Layout   >
      <Carousel
        autoPlay={true}
        animation="slide"
        indicators={false}
        navButtonsAlwaysVisible={true}
        cycleNavigation={true}
        navButtonsProps={{
          style: {
            background: "#fff",
            color: "#494949",

            borderRadius: 0,
            marginTop: 0,
            height: "104px",
            // Adjusting styles for mobile devices
            "@media (max-width: 768px)": {
              marginTop: -12,
              height: "64px",
            },
            "@media (max-width: 480px)": {
              marginTop: -8,
              height: "28px",
            },
          },
        }}
      >
        {images.map((image, i) => (
          <img
            src={image.img}
            alt={`img-${i}`}
            key={i}
            className="banner_img   h-[40vh]  md:h-[90vh]  "
          />
        ))}
      </Carousel>

      {/* ===================================next part============================= */}
      <section className="bg-[#202020]   relative  ">
        <div className="row mb-[3rem]">
          <div className="pt-[3rem]">
            <div className="theme-section-module text-center">
              <h2
                className="uppercase      text-[2rem]  xl:text-[3rem] font-[400]  text-white"
              
              >
                Alpha Enterprises Pvt. Ltd
              </h2>
              <div
                className="w-[25rem]      md:w-[30rem] mx-auto mt-7 border-b border-gray-700 relative"
              >
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <svg
                    className="text-[#fcaa52] w-[4rem] h-[4rem] text-center"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container  px-2  md:px-[15px]      w-full   xl:w-[80vw] mx-auto">
          <div className=" grid grid-cols-1   lg:grid-cols-3 xl:grid-cols-3">
            {alpha.map((data) => (
              <div className="    hover:bg-[#00000056] px-2 hover:translate-x-2  cursor-pointer all-transition duration-500">
                <div className="block px-5  py-8 ">
                  <div className=" border-[3px]  w-20 h-20 rounded-[40px] mx-auto  mb-5  border-[#fcaa52]">
                    <data.icon className="text-[#fcaa52] mt-3 p-2   text-center  ml-3  text-[3rem]" />
                  </div>

                  <div className="media-body align-self-center ">
                    <h5 className="leading-8 font-[600] text-[2rem] text-white">
                      {data.title}
                    </h5>
                    <span className="info-details  hover:text-[#fcaa52] text-[1rem] mt-3 flex text-white ">
                      {data.description}
                      {/* <FaLongArrowAltRight className="text-[1.5rem]  mt-[0.2rem] ml-3" /> */}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* ===============================part 3 ================================== */}

      

      {/* /================================part 4  ================================/ */}

      <section
        className="bg-[#202020] text-white    border-t-[3px]    border-red-700"
        id="theme-project"
      >
        <div className="">
          <div className="row">
            <div className="pt-[3rem]">
              <div className="theme-section-module text-center">
                <p className="text-[#fcaa52] text-center text-[1.5rem]">
                  Our portfolio
                </p>
                <h2 className="uppercase     text-[2rem] xl:text-[3rem] font-[400]">
                  SURVEILLANCE SOLUTIONS
                </h2>
                <div
                  className="w-[25rem]     xl:w-[30rem] mx-auto mt-7 border-b border-gray-700 relative"
                  data-wow-delay=".3s"
                >
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <svg
                      className="text-[#fcaa52] w-[4rem] h-[4rem] text-center"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    </svg>
                  </div>
                </div>
                <p className="text-xl  w-[80vw]  mx-auto   mt-8">
                  It can be said that the world today is growing in leaps and
                  bounds but unfortunately the threat to our individual
                  concerns, be it to our facilities and firms or organizations
                  has been growing at an alarming rate at the same time. So now
                  a days security has become an essential and mandatory
                  requirement for all of us, no matter what level we are dealing
                  with.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full mt-10">
            <div className="row grid grid-cols-1 md:gap-0  md:grid-cols-2  lg:grid-cols-3  xl:grid-cols-4">
              {/* Corrected map method */}
              {services.map((service) => (
                <div className="grid-cols-1 mt-5 " key={service.id}>
                  <article className="theme-project-content mb-0 relative transition-transform duration-300 transform">
                    <figure className="portfolio-thumbnail w-full   h-[40vh]  md:h-[60vh] lg:h-[60vh]   xl:h-[50vh] relative">
                      <img
                        src={service.img}
                        className="w-full h-full"
                        alt={`${service.title} images`}
                      />
                      <div className="content-overlay bg-black bg-opacity-75 absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-500" />
                      <div className="click-view absolute inset-0 flex flex-row justify-center opacity-0 hover:opacity-75 transition-opacity duration-300 bg-[#000]">
                        <h5 className="theme-project-title     px-2     text-white text-2xl mt-5 text-center font-bold">
                          {service.title}
                          <br />
                          <p className="text-[1rem] mt-5 text-white  px-3   text-left">
                            {service.description}
                          </p>
                        </h5>
                      </div>
                    </figure>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* ========================part 5 ============================== */}

      <div className=" pl-0 pr-0  h-[10rem] text-center border-b-[3px] border-t-[3px]  border-red-700   pt-5 pb-5 bg-gray-400 w-full">
        <div className="row    w-[80vw]  mx-auto">
          <div className="  w-[80vw]  ">
            <div className="mt-[2.5rem]">
              <Link
                to="#"
                className="bg-transparent  text-[#111] font-[600]  hover:bg-black hover:text-white border-2  py-2  px-8  border-black "
              >
                VIEW ALL PROJECTS
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* =================================part 6 ================================== */}

      <section
        className="w-full border-b-[3px] relative border-red-700"
        id="theme-testimonial"
        style={{
          backgroundImage: `url(${clientbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className=" h-full absolute border-black border-2 opacity-30 w-full  " />

        <div className="  w-full  bg-opacity-70 mx-auto text-white   ">
          <div className="row   ">
            <div className="  pt-[3rem]">
              <div className=" text-center">
                <p className="  text-[#fcaa52]  text-center cursor-pointer  text-[1.5rem]">
                  Our portfolio
                </p>
                <h2 className="uppercase  text-[3rem]  cursor-pointer  font-[400]   ">
                  OUR CLIENTS
                </h2>
                <div
                  className=" w-[23rem] mx-auto mt-7 border-b-[3px]   border-black relative"
                  data-wow-delay=".3s"
                >
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <svg
                      className="text-[#fcaa52] w-[4rem] h-[4rem] text-center"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="  px-[2rem]   w-[95vw]  xl:w-[80vw]  pb-10 mx-auto  grid  grid-cols-1    md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-[20px]   mt-20  ">
            <div className="border-t-[4px]  border-red-700 theme-testimonial-block bg-black bg-opacity-50 relative text-center mb-16 p-10">
              <article>
                <figure className="thumbnail mx-auto mb-8 w-16 h-16 relative">
                  <img
                    src={ubank}
                    className="img-fluid   rounded-full"
                    alt="Mohamed Essa Al Taheri"
                  />
                </figure>
                <div className="testimonial-content   py-2   ">
                  <p className="text-[1.1rem] leading-[2.4rem] text-[#eceff3]">
                    “U Microfinance Bank Ltd can safely &amp; thankfully
                    recommend M/s Alpha Enterprises Pvt Ltd to anycompany who
                    desires to hire their services and certainly ,havefull
                    confidence on them for the products services being provided.
                  </p>
                  <cite className="text-[#fcaa52] text-[1.5rem] font-[600] block">
                    Mohamed Essa Al Taheri
                  </cite>
                  <span className="position ">President & CEO (Acting)</span>
                </div>
              </article>
            </div>

            <div className="border-t-[4px]    border-red-700 theme-testimonial-block bg-black bg-opacity-50  relative text-center mb-16 p-10">
              <article>
                <figure className="thumbnail mx-auto mb-8 w-16 h-16 relative">
                  <img
                    src={moblink}
                    className="img-fluid   rounded-full"
                    alt="Sumair Sajjad"
                  />
                </figure>
                <div className="testimonial-content   py-2   ">
                  <p className="text-[1.1rem] leading-[2.4rem] text-[#eceff3]">
                    This is to certify that M/s Alpha Enterprises Pvt Ltd is
                    working with our organization since March 2019. We found
                    them very dedicated to the work assigned. They are result
                    oriented, professional and sincere.
                  </p>
                  <cite className="text-[#fcaa52] text-[1.5rem] font-[600] block">
                    TARIQ AYAZ
                  </cite>
                  <span className="position ">Manager Administration</span>
                </div>
              </article>
            </div>

            <div className="border-t-[4px]  border-red-700 theme-testimonial-block bg-black bg-opacity-50  relative text-center mb-16 p-10">
              <article>
                <figure className="thumbnail mx-auto mb-8 w-16 h-16 relative">
                  <img
                    src={Dubaibank}
                    className="img-fluid   rounded-full"
                    alt="Sumair Sajjad"
                  />
                </figure>
                <div className="testimonial-content   py-2   ">
                  <p className="text-[1.1rem] leading-[2.4rem] text-[#eceff3]">
                    We would like to complement for the excellent services
                    provided by M/s Alpha Enterprises Pvt Ltd in terms of
                    Surveillance Fire Alarm System to Dubai Islamic Bank Ltd. We
                    have always been pleased with the services received from all
                    of its staff.
                  </p>
                  <cite className="text-[#fcaa52] text-[1.5rem] font-[600] block">
                    Kashif Hanif
                  </cite>
                  <span className="position ">Manager Administration</span>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      {/* =====================================part 4 =================================================== */}
      <MultiCarosel />

      {/* ===================================part 5 ==================================== */}

      <section
        id="testimonials"
        className="bg-fixed bg-cover bg-center  pt-[120px] pb-[90px]"
        style={{
          backgroundImage: `url(${clientbg1})`,
        }}
        data-speed={20}
        data-type="background"
      >
        <div className="container  px-[15px] mx-auto  w-[80vw]  text-white">
          <div className="row">
            <div
              className="col-md-8 col-md-offset-2 section-heading"
              style={{ textAlign: "center" }}
            >
              <h1 className="text-3xl  font-bold  mb-7 ">
                WHAT OUR CLIENTS SAYS
              </h1>
              <p className="line  w-[5rem] mx-auto mb-[3rem] border-b-4 border-white"></p>
            </div>
          </div>

          <div className="text-center  my-5">
            <Carousel useKeyboardArrows={true}>
              {contentData.map((data) => (
                <div className="" key={data.id}>
                  <p className="text-2xl">{data.ptag}</p>
                  <h3 className="text-2xl font-bold my-5">{data.post}</h3>
                  <span className="my-[5rem] text-2xl font-bold  text-[1rem]">
                    {data.location}
                  </span>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Home;
