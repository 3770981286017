import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import "./whatsapp.css";
import logo from "../../images/image.png";

const Whatsapp = () => {
  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="+923455185310"
        accountName="Alphaenterprises"
        avatar={logo}
        chatMessage="Thank you for contacting Alpha Enterprises! Please let us know how we can help you."
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
    </div>
  );
};

export default Whatsapp;
